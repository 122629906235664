<template>
  <div class="letter-creation-step">
    <div class="step-heading__header">
      <h1 class="step-heading__title">Start with your header</h1>
      <p class="step-heading__text">
        Provide employers your complete name and contact details.
      </p>
    </div>

    <FirstStepForm
      v-if="letterData"
      :userData="letterData"
      @next="goNext"
      @input="updatePreview"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { CREATING_COVER_LETTER_NAMES } from "@/helpers/rout-constants";
import FirstStepForm from "@/components/creation/FirstStepForm";
import { setIntermediateLetter } from "@/helpers/save-intermediate-data";
import ResumeHash from "@/helpers/resume-hash";
import LetterHash from "@/helpers/cover-letter-hash";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import store from "@/store";

export default {
  name: "StepContactInfoLetterCreation",
  mixins: [mixpanelMixin],
  components: {
    FirstStepForm,
  },
  data() {
    return {
      letterData: null,
      nextStep: CREATING_COVER_LETTER_NAMES.STEP_2,
    };
  },
  computed: {
    ...mapGetters({
      coverLetter: "coverLetterCreation/getCoverLetter",
      isLoggedIn: "auth/isLoggedIn",
    }),
  },

  methods: {
    getHash(type) {
      let completed_template_hash;
      const completed_template = CompletedTemplate.get();
      if (completed_template) {
        if (completed_template.type == type) {
          completed_template_hash = completed_template.hash;
        }
      }
      const hash = type == "resume" ? ResumeHash.get() : LetterHash.getBackUp();
      return completed_template_hash || hash;
    },
    async updatePreview(data) {
      await this.$store.dispatch("coverLetterCreation/updateCoverLetter", data);

      await setIntermediateLetter(this.coverLetter.hash);
    },
    async getInitialLetter() {
      await this.$store.dispatch("loader/pending");

      this.letterData = await this.$store.dispatch(
        "coverLetterCreation/getCoverLetter"
      );

      // let allResume;
      // let allCoverLetter;
      // if (this.isLoggedIn) {
      //   allResume = await this.$store.dispatch("userDashboard/getAllResumes");
      //   allCoverLetter = await this.$store.dispatch(
      //     "userDashboard/getAllCoverLetters"
      //   );
      // }

      // if (allResume ? allResume.length > 0 : false) {
      //   this.letterData = allResume[0];
      //   console.log("1", this.letterData);
      // } else if (!this.isLoggedIn && this.getHash("resume")) {
      //   this.letterData = await this.$store.dispatch(
      //     "resumeCreation/getResume",
      //     this.getHash("resume")
      //   );
      //   console.log("2", this.letterData);
      // } else if (allCoverLetter ? allCoverLetter.length > 1 : false) {
      //   this.letterData = allCoverLetter[1];
      //   console.log("3", this.letterData);
      // } else if (!this.isLoggedIn && this.getHash("letter")) {
      //   this.letterData = await this.$store.dispatch(
      //     "coverLetterCreation/getCoverLetter",
      //     this.getHash("letter")
      //   );
      //   console.log("4", this.letterData);
      // } else if (!this.coverLetter) {
      //   try {
      //     this.letterData = await this.$store.dispatch(
      //       "coverLetterCreation/getCoverLetter"
      //     );
      //     console.log("5", this.letterData);
      //   } catch (err) {
      //     console.error(err);
      //   }
      // } else {
      //   this.letterData = this.coverLetter;
      //   console.log("6", this.letterData);
      // }

      await this.$store.dispatch("loader/done");
    },
    async goNext(userData) {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch(
          "coverLetterCreation/updateCoverLetter",
          userData
        );
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
      await this.$router.push({ name: this.nextStep });
    },
  },

  async created() {
    this.mixpanelTrack("V2-BASEINFOLETTER");

    await this.$store.dispatch(
      "coverLetterCreation/setPreviewLoadingState",
      true
    );
    await this.getInitialLetter();
    await store.dispatch(
      "coverLetterCreation/getCoverLetterPreviewAll",
      LetterHash.get()
    );

    await store.dispatch("coverLetterCreation/setPreviewLoadingState", false);
  },
};
</script>

<style scoped lang="scss">
.step-heading__header {
  margin-bottom: 48px;
}

.letter-creation-step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
